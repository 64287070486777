<script lang="ts" setup>
import type { SwiperOptions } from 'swiper'
import { Autoplay, Navigation, Swiper } from 'swiper'

Swiper.use([Autoplay, Navigation])

const props = withDefaults(
  defineProps<{
    isGallery?: boolean
    options?: SwiperOptions
  }>(),
  { isGallery: true, options: () => ({}) }
)

const navigationNextRef = ref<HTMLElement | null>(null)
const navigationPrevRef = ref<HTMLElement | null>(null)
const sliderRef = ref<HTMLElement | null>(null)
const swiper = ref<Swiper | null>(null)

onMounted(() => {
  if (props.isGallery) {
    swiper.value = new Swiper(
      sliderRef.value as HTMLElement,
      {
        navigation: {
          nextEl: navigationNextRef.value,
          prevEl: navigationPrevRef.value
        },
        ...props.options
      }
    )
  }
})
</script>

<template>
  <div class="container gallery-container">
    <div ref="sliderRef" class="swiper-container gallery">
      <div class="swiper-wrapper">
        <slot />
      </div>
    </div>

    <template v-if="isGallery">
      <div ref="navigationPrevRef" class="swiper-button-prev swiper-button-disabled">
        <SvgIcon name="outlined/navigate-before" />
      </div>

      <div ref="navigationNextRef" class="swiper-button-next">
        <SvgIcon name="outlined/navigate-next" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/gallery';

.gallery-container {
  position: relative;

  > .swiper-button-prev {
    left: calc(16px - 40px / 2);
  }

  > .swiper-button-next {
    right: calc(16px - 40px / 2);
  }

  @media (min-width: 1280px) {
    > .swiper-button-prev {
      left: calc(40px - 40px / 2);
    }

    > .swiper-button-next {
      right: calc(40px - 40px / 2);
    }
  }
}
</style>
